<template>
  <div class="message-config">
    <en-table-layout :table-data="tableData1.data">
      <template slot="toolbar">
        <el-form-item label="短信状态">
          <el-select v-model="params.shopDisable" size="medium" placeholder="选择短信状态" @change="inpuchange">
            <el-option label="全部" value />
            <el-option v-for="sms in smsList" :key="sms.id" :label="sms.czt" :value="sms.id" />
          </el-select>
        </el-form-item>

        <el-form-item label="关键词">
          <el-input v-model="params.keyword" size="medium" placeholder="请输入关键词" />
        </el-form-item>

        <div class="col-auto">
          <el-button size="small" type="primary" @click="getzdlist">搜索</el-button>
        </div>
      </template>

      <template slot="table-columns">
        <el-table-column prop="shop_name" label="站点名称"></el-table-column>
        <!-- <el-table-column label="商城类型">
          <template slot-scope="scope">
            {{ scope.row.mall_type === 1 ? '积分商城' : '现金商城'}}
          </template>
</el-table-column> -->
        <el-table-column prop="shop_createtime" label="创建时间" width="160"></el-table-column>
        <el-table-column prop="sign_name" label="签名配置"></el-table-column>
        <el-table-column prop="shop_disable" label="状态"></el-table-column>
        <el-table-column prop="cz" label="操作" width="105">
          <template slot-scope="scope">
            <el-button size="mini" type="primary" @click="handleEdit(scope.$index, scope.row)">
              修改签名
            </el-button>
          </template>
        </el-table-column>
      </template>

      <el-pagination slot="pagination" @size-change="handlePageSizeChange" @current-change="handlePageCurrentChange"
        :current-page="tableData1.page_no" :page-size="tableData1.page_size" :page-sizes="MixinPageSizes"
        :layout="MixinTableLayout" background :total="tableData1.data_total"></el-pagination>
    </en-table-layout>

    <el-dialog title="签名配置" :visible.sync="flag" width="600px" :close-on-click-modal="false"
      :close-on-press-escape="false">
      <el-form :model="signConfigForm" ref="signConfigForm" label-width="0px">
        <el-form-item label="">您可从已申请通过的短信签名中，选择一个作为短信发送的签名</el-form-item>

        <el-form-item>
          <el-select size="medium" style="width:200px;" v-model="signConfigForm.sign_id" clearable>
            <el-option v-for="item in signTypeList" :key="item.sign_id" :label="item.sign_name"
              :value="item.sign_id"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="">还没有个性短信签名？
          <router-link to="/finance/messageManage" style="color:#1A43A9;">立即申请</router-link></el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="flag = false">取 消</el-button>
        <el-button type="primary" @click="submitForm">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import * as API_account from "@/api/account";
import EnTableLayout from '../../../../ui-components/TableLayout/src/main';
export default {
  components: { EnTableLayout },
  data () {
    return {
      flag: false,
      total: 0,
      currentPage3: 1,
      smsList: [
        {
          czt: "OPEN",
          id: 1
        },
        {
          czt: "CLOSE",
          id: 0
        }
      ],
      phases: [
        {
          cname: "积分商城",
          id: "0"
        },
        {
          cname: "现金商城",
          id: "1"
        }
      ],
      formInline: {},
      params: {
        page_no: 1,
        page_size: 20,
        mall_type: "",
        shopDisable: "",
        keyword: ""
      },
      tableData1: {},
      indexs: 0,
      signTypeList: [],
      signConfigForm: {
        sign_id: "",
        shop_id: ""
      }
    };
  },
  created () {
    this.getzdlist();
    this.GET_ManageList();
  },
  methods: {
    GET_ManageList () {
      API_account.ManageListAll({}).then(res => {
        this.signTypeList = res;
      });
    },
    /** 分页大小发生改变 */
    handlePageSizeChange (size) {
      this.params.page_size = size;
      this.getzdlist();
    },

    /** 分页页数发生改变 */
    handlePageCurrentChange (page) {
      this.params.page_no = page;
      this.getzdlist();
    },
    getzdlist () {
      let params = this.params;
      API_account.pzManagelist(params).then(res => {
        res.data.map(item => {
          let time = item.shop_createtime;
          function timestampToTime (timestamp) {
            var date = new Date(timestamp * 1000); //时间戳为10位需*1000，时间戳为13位的话不需乘1000
            var Y = date.getFullYear() + "-";
            var M =
              (date.getMonth() + 1 < 10
                ? "0" + (date.getMonth() + 1)
                : date.getMonth() + 1) + "-";
            var D =
              (date.getDate() < 10 ? "0" + date.getDate() : date.getDate()) +
              " ";
            var h =
              (date.getHours() < 10 ? "0" + date.getHours() : date.getHours()) +
              ":";
            var m =
              (date.getMinutes() < 10
                ? "0" + date.getMinutes()
                : date.getMinutes()) + ":";
            var s =
              date.getSeconds() < 10
                ? "0" + date.getSeconds()
                : date.getSeconds();
            return Y + M + D + h + m + s;
          }
          item.shop_createtime = timestampToTime(time);
          if (item.mall_type == 1) {
            item.mall_type = "积分商城";
          } else {
            item.mall_type = "现金商城";
          }
        });

        this.tableData1 = res;
      });
    },
    handleEdit (res, row) {
      this.indexs = res;
      this.flag = true;
      this.signConfigForm.shop_id = row.shop_id;
      this.signConfigForm.sign_id = "";
      if (row.sign_id) this.signConfigForm.sign_id = row.sign_id;
    },
    inpuchange () {
      console.log(this.params);
      this.getzdlist();
    },
    submitForm () {
      API_account.changeDPManage(
        this.signConfigForm,
        this.signConfigForm.shop_id
      ).then(res => {
        this.$message.success("修改成功！");
        this.getzdlist();
        this.flag = false;
      });
    },
    resetForm () {
      this.flag = false;
    }
  }
};
</script>

<style lang="scss"></style>
